import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Menu from './Components/navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from './Components/about';
import Service from './Components/service';
import Contact from './Components/contact';
import Home from './Components/home';
import call from './img/call.gif';
import Footer from './Components/footer';
import Service1 from './Components/website-designing';
import Service2 from './Components/website-development';
import Service3 from './Components/digital-marketing';
import Service4 from './Components/graphics-designing';
import Service5 from './Components/cctv-services';
import Service6 from './Components/computer-lab';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
  <React.StrictMode>
    <BrowserRouter>
    <Menu />
      <Routes>
          <Route path="" element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/service' element={<Service />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/website-designing' element={<Service1 />} />
          <Route path='/website-development' element={<Service2 />} />
          <Route path='/digital-marketing' element={<Service3 />} />
          <Route path='/graphics-designing' element={<Service4 />} />
          <Route path='/cctv-services' element={<Service5 />} />
          <Route path='/computer-lab' element={<Service6 />} />
      </Routes>
      <div className='call'>
        <a href='tel:916207408069'><img src={call} alt="Call" style={{height:40}} /></a>
      </div>
      <Footer />
    </BrowserRouter>
  </React.StrictMode>
  
);


reportWebVitals();
