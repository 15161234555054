import {Form, Button } from "react-bootstrap";
function Enquiry()
{
    return(
        <><center><h5 style={{color:"#5a07cb"}}><strong>Get A Free Quote Now</strong></h5>
            <p>Please complete this form to be connected by one of our experts</p></center><hr style={{border:"2px solid #2f7f8a"}} />
            <Form method="post">
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" placeholder="Enter Your Name *" required pattern="{A-Za-z ]{3,}" />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Email ID</Form.Label>
                <Form.Control type="email" placeholder="Enter Your Email *" required />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Mobile No.</Form.Label>
                <Form.Control type="text" placeholder="Mobile No." required pattern="[0-9]{10}" />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Message</Form.Label>
                <Form.Control as="textarea" rows={3} placeholder="Describe your requirement" />
              </Form.Group>

              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form></>
    )
}

export default Enquiry;