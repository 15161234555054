import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../img/logo.png';
import { Link } from "react-router-dom";
function Menu()
{
    return(
    <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
      <Container fluid>
        <Navbar.Brand href=""><Link to=""><img src={logo} alt="Logo" className='logo' /></Link></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link><Link to="" className='m1'>Home</Link></Nav.Link>
            <Nav.Link><Link to="about" className='m1'>About Us</Link></Nav.Link>
            <Nav.Link><Link to="service" className='m1'>Services</Link></Nav.Link>
            <Nav.Link><Link to="contact" className='m1'>Contact Us</Link></Nav.Link>
            <Nav.Link><Link to="" className='m1'>Branch</Link></Nav.Link>
            <Nav.Link><Link to="" className='m1'>Franchise</Link></Nav.Link>
          </Nav>
          
        </Navbar.Collapse>
      </Container>
    </Navbar>
    )
}

export default Menu;