import { Col, Container, Row } from "react-bootstrap";

function Contact()
{
    return(
        <Container fluid>
            <Row className="pd">
                <Col className="heading1">
                    <h2 className="h2">Contact Us</h2>    
                </Col>
            </Row>
        </Container>
    )
}

export default Contact;