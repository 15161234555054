import { Col, Container, Row} from "react-bootstrap";
import img1 from "../img/computer-lab.png";
import Enquiry from "./enquiry";
function Service6()
{
    return (
      <Container>
        <Row className="pd">
          <Col className="heading1">
            <h2 className="h2">Computer Lab Setup</h2>
          </Col>
        </Row>
        <Row style={{ paddingTop: "40px" }} className="align-items-center">
          <Col md="6">
            <h1 style={{ color: "#330066", textAlign: "center" }}>
              Computer Lab Setup & Services Company in Ranchi
            </h1>
          </Col>
          <Col md="6">
            <img src={img1} alt="About Web Design" className="img-fluid galleryImg" />
          </Col>
        </Row>
        <Row style={{ paddingTop: "30px" }}>
          <Col
            md="7"
            style={{
              boxShadow: "2px 2px 10px #ccc",
              padding: "15px",
              borderRadius: "10px",
              margin:"20px"
            }}
          >
            <p style={{ textAlign: "justify" }}>
            SP WEBCARE is a leading provider of turnkey solutions for computer laboratories. With years of expertise and a deep understanding of computer technology, we are committed to helping our clients harness the full potential of innovation and research in the field. Our dedicated team of computer hardware specialists, engineers, and project managers collaborates to design, deploy, and maintain labs tailored to your specific needs.
            </p>
            <p style={{ textAlign: "justify" }}>
              <h5>
                <strong>Why You Choose Us</strong>
              </h5>
              <p style={{ textAlign: "justify" }}>
                SP WEBCARE, our uniqueness sets us apart in the industry. We’re not just another laboratory setup company; we’re a catalyst for innovation, a partner in progress, and a provider of exceptional value. Here’s what makes us truly unique:
                </p>
                <ul type="circle">
                    <li><strong>Expertise:</strong> Our team consists of robotics specialists with a deep understanding of the technology and its applications.</li>
                    <li><strong>Customization:</strong> We tailor our solutions to meet your specific needs and budget, ensuring maximum return on investment (ROI).</li>
                    <li><strong>End-to-End Service:</strong> From initial consultation to ongoing support, we provide a complete suite of services to simplify your computer lab setup journey.</li>
                    <li><strong>Quality Assurance:</strong> We use only the highest quality equipment and software to guarantee reliable and consistent performance.</li>
                    <li><strong>Compliance:</strong> We adhere to all relevant safety and regulatory standards to ensure a secure and compliant lab environment.</li>
                </ul>
            </p>
            
          </Col>
          <Col
            md="4"
            style={{
              boxShadow: "2px 2px 10px #ccc",
              padding: "15px",
              borderRadius: "10px",
              margin:"20px"
            }}
          >
            <Enquiry />
          </Col>
        </Row>
      </Container>
    );
}

export default Service6;