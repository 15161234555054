import { Col, Container, Row} from "react-bootstrap";
import img1 from "../img/graphics.png";
import Enquiry from "./enquiry";
function Service4()
{
    return (
      <Container>
        <Row className="pd">
          <Col className="heading1">
            <h2 className="h2">Graphics Designing</h2>
          </Col>
        </Row>
        <Row style={{ paddingTop: "40px" }} className="align-items-center">
          <Col md="6">
            <h1 style={{ color: "#330066", textAlign: "center" }}>
              Graphics Design Company in Ranchi
            </h1>
          </Col>
          <Col md="6">
            <img src={img1} alt="About Web Design" className="img-fluid galleryImg" />
          </Col>
        </Row>
        <Row style={{ paddingTop: "30px" }}>
          <Col
            md="7"
            style={{
              boxShadow: "2px 2px 10px #ccc",
              padding: "15px",
              borderRadius: "10px",
              margin:"20px"
            }}
          >
            <p style={{ textAlign: "justify" }}>
            We have grown horizontally & vertically in the portfolio of our services. Design thinking, rich clientele, years of experience, exposure to uncertainties have improved what we deliver.
            </p>
            
            <p style={{ textAlign: "justify" }}>
              <h5>
                <strong>Brand Design</strong>
              </h5>
              Well begun is half done. That's true with the launch of your brand too. We understand the vitality of launch. We deliver the branding designs that are as unique & creative as your business.
            </p>
            <p style={{ textAlign: "justify" }}>
              <h5>
                <strong>Logo Design</strong>
              </h5>
              Logo is what your prospects & clients remember your business as. That stays in minds & create emotions. We invest our thoughts & creativity in designing your logo that spells your brand values.
            </p>
            <p style={{ textAlign: "justify" }}>
              <h5>
                <strong>Graphics Design</strong>
              </h5>
              Brochures. Catalogues. Packaging. Infographics. Banners. Posters. Billboards. Print. Digital. ATL. BTL. You name it. We design it.
            </p>
            <p style={{ textAlign: "justify" }}>
              <h5>
                <strong>Corporate Design</strong>
              </h5>
              We design corporate presentations, pitches, gifts, communications etc. that play a vital role in sustaining & enhancing the brand value. 
            </p>
          </Col>
          <Col
            md="4"
            style={{
              boxShadow: "2px 2px 10px #ccc",
              padding: "15px",
              borderRadius: "10px",
              margin:"20px"
            }}
          >
            <Enquiry />
          </Col>
        </Row>
      </Container>
    );
}

export default Service4;