import { Col, Container, Row } from "react-bootstrap";
import ab from '../img/about.gif';
function About()
{
    return(
        <Container>
            <Row className="pd">
                <Col className="heading1">
                    <h2 className="h2">About Us</h2>    
                </Col>
            </Row>
        <Row style={{paddingTop:"30px"}}>
          <Col>
          <h6 style={{textAlign:"center",color:"#330066"}}>We believe in power of User Experience that Strategic Insight, Responsive Layouts, Unique Design, Clean Coding and Deliver.</h6>
          </Col>
        </Row>
        <Row style={{paddingTop:"15px"}}>
          <Col style={{textAlign:"justify"}} md="6">
            <p>SP WEBCARE was established in 2017 Promoting Director Shyam Sundar Pradhan with a vision of delivering innovative and cost effective solutions leveraging Open Source and hardware. The company has its headquarters in Ranchi, India and has two development centers in India located in Ranchi and Seraikella-Kharsawan, Jharkhand. Our offring include smart and optimised design for web, mobile and tablet, hosting, maintenance and support services.</p>
            <p>At SP WEBCARE we are a team of young, passionate and creative people who aim to offer world-class solutions for all your web design and development needs. We create meaningful brand digital experiences, from domain registration to website design and finally website hosting for all small, medium and large entrepreneurs around the world.</p>
          </Col>
          <Col style={{textAlign:"center"}} md="6">
            <br /><img src={ab} className='img-fluid galleryImg' alt="about" />
            
          </Col>
        </Row>
    </Container>
        
    )
}

export default About;