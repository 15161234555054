import { Col, Container, Row,Card } from "react-bootstrap";
import ser1 from '../img/service (6).png';import ser2 from '../img/service (4).png';
import ser3 from '../img/service (3).png';import ser4 from '../img/service (5).png';
import ser5 from '../img/service (2).png';import ser6 from '../img/service (1).png';
import { Link } from "react-router-dom";
function Service()
{
    return(
        <Container>
            <Row className="pd">
                <Col className="heading1">
                    <h2 className="h2">Our Services</h2>    
                </Col>
            </Row>
      <Row style={{paddingTop:"40px"}}>
        <Col>
          <h6 style={{textAlign:"center",color:"#330066"}}>We Offer Professional Solutions For Your Business</h6>
        </Col>
      </Row>
      <Row style={{paddingTop:"15px"}}>
          <Col style={{textAlign:"justify",padding:"10px"}} md="4">
            <Card style={{boxShadow:'2px 2px 10px #000' }}>
              <Card.Header className='bg-warning' />
              <Card.Body style={{textAlign:'center'}}>
                <img src={ser1} style={{height:80,width:80}} alt="" />
                <Card.Title style={{color:"#330066"}}>Website Designing</Card.Title>
                <Card.Text>
                Stand out from the crowd with a stunning website by SP WEBCARE Web Design Services.
                </Card.Text>
                <Link to="/website-designing" className='btn btn-primary btn-sm'>Read More</Link>
              </Card.Body>
            </Card>
          </Col>
          <Col style={{textAlign:"justify",padding:"10px"}} md="4">
            <Card style={{ boxShadow:'2px 2px 10px #000' }}>
              <Card.Header className='bg-warning' />
              <Card.Body style={{textAlign:'center'}}>
                <img src={ser2} style={{height:80,width:80}} alt="" />
                <Card.Title style={{color:"#330066"}}>Website Development</Card.Title>
                <Card.Text>
                Secure, Intuitive, Scalable web apps to help you exponentially grow your business.
                </Card.Text>
                <Link to="/website-development" className='btn btn-primary btn-sm'>Read More</Link>
              </Card.Body>
            </Card>
          </Col>
          <Col style={{textAlign:"justify",padding:"10px"}} md="4">
            <Card style={{ boxShadow:'2px 2px 10px #000' }}>
              <Card.Header className='bg-warning' />
              <Card.Body style={{textAlign:'center'}}>
                <img src={ser3} style={{height:80,width:80}} alt="" />
                <Card.Title style={{color:"#330066"}}>Digital Marketing</Card.Title>
                <Card.Text>
                It is a strategy that uses multiple channels to attract engage and convert customers online.
                </Card.Text>
                <Link to="/digital-marketing" className='btn btn-primary btn-sm'>Read More</Link>
              </Card.Body>
            </Card>
          </Col>
          <Col style={{textAlign:"justify",padding:"10px"}} md="4">
            <Card style={{ boxShadow:'2px 2px 10px #000' }}>
              <Card.Header className='bg-warning' />
              <Card.Body style={{textAlign:'center'}}>
                <img src={ser4} style={{height:80,width:80}} alt="" />
                <Card.Title style={{color:"#330066"}}>Graphics Designing</Card.Title>
                <Card.Text>
                Stand out from the crowd with a stunning website by SP WEBCARE Web Design Services.
                </Card.Text>
                <Link to="/graphics-designing" className='btn btn-primary btn-sm'>Read More</Link>
              </Card.Body>
            </Card>
          </Col>
          <Col style={{textAlign:"justify",padding:"10px"}} md="4">
            <Card style={{ boxShadow:'2px 2px 10px #000' }}>
              <Card.Header className='bg-warning' />
              <Card.Body style={{textAlign:'center'}}>
                <img src={ser5} style={{height:80,width:80}} alt="" />
                <Card.Title style={{color:"#330066"}}>CCTV Services</Card.Title>
                <Card.Text>
                Stand out from the crowd with a stunning website by SP WEBCARE Web Design Services.
                </Card.Text>
                <Link to="/cctv-services" className='btn btn-primary btn-sm'>Read More</Link>
              </Card.Body>
            </Card>
          </Col>
          <Col style={{textAlign:"justify",padding:"10px"}} md="4">
            <Card style={{boxShadow:'2px 2px 10px #000' }}>
              <Card.Header className='bg-warning' />
              <Card.Body style={{textAlign:'center'}}>
                <img src={ser6} style={{height:80,width:80}} alt="kkkl" />
                <Card.Title style={{color:"#330066"}}>Computer Lab</Card.Title>
                <Card.Text>
                Stand out from the crowd with a stunning website by SP WEBCARE Web Design Services.
                </Card.Text>
                <Link to="/computer-lab" className='btn btn-primary btn-sm'>Read More</Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        </Container>
    )
}

export default Service;