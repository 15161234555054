import { Col, Container, Row} from "react-bootstrap";
import img1 from "../img/digital_marketing.png";
import Enquiry from "./enquiry";
function Service3()
{
    return (
      <Container>
        <Row className="pd">
          <Col className="heading1">
            <h2 className="h2">Digital Marketing</h2>
          </Col>
        </Row>
        <Row style={{ paddingTop: "40px" }} className="align-items-center">
          <Col md="6">
            <h1 style={{ color: "#330066", textAlign: "center" }}>
              Digital Marketing Company in Ranchi
            </h1>
          </Col>
          <Col md="6">
            <img src={img1} alt="About Web Design" className="img-fluid galleryImg" />
          </Col>
        </Row>
        <Row style={{ paddingTop: "30px" }}>
          <Col
            md="7"
            style={{
              boxShadow: "2px 2px 10px #ccc",
              padding: "15px",
              borderRadius: "10px",
              margin:"20px"
            }}
          >
            <p style={{ textAlign: "justify" }}>
              SP WEBCARE is an advanced digital marketing company in Ranchi that enables brands to communicate and interface with their audiences in a much better way through entire computerized transformation. We improve the brands online value through a mix of performance-enhancing approach, creative drives, latest methodologies and development. SP WEBCARE, Ranchi engage our customers to develop their business, utilizing multiple digital marketing stages and platforms.
            </p>
            <p style={{ textAlign: "justify" }}>
            The ideas of Digital Marketing are advancing colossally among businesses, particularly by the top digital marketing companies in every industry using the mass reach of computerized methodologies. The undertaking of Digital Marketing incorporates SEO, SEM, content marketing, Campaign promotions and e-Commerce advertising. At the same time, it stretches out to offline channels that give social media, for example, cell phones, ringtones, e-mail, social media marketing, e-Books, optical games, display advertising and various another type of advanced digital media. Being one of the <strong>best digital marketing company in Ranchi</strong>.
            </p>
            <p style={{ textAlign: "justify" }}>
              <h5>
                <strong>We provide the following digital marketing services:</strong>
              </h5>
              <ul>
                <li>Search Engine Marketing (SEM)</li>
                <li>Search Engine Optimisation (SEO)</li>
                <li>Social Media Marketing (SMM)</li>
                <li>Social Media Management</li>
              </ul>
            </p>
            
          </Col>
          <Col
            md="4"
            style={{
              boxShadow: "2px 2px 10px #ccc",
              padding: "15px",
              borderRadius: "10px",
              margin:"20px"
            }}
          >
            <Enquiry />
          </Col>
        </Row>
      </Container>
    );
}

export default Service3;