import bg1 from "../img/te-bg.jpg";
import fb from "../img/social (3).png";
import ins from "../img/social (1).png";
import yt from "../img/social (4).png";
import tt from "../img/social (2).png";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
function Footer()
{
    return (
      <Container fluid style={{paddingTop:"20px"}}>
        <Row style={{ background: "url(" + bg1 + ")" }}>
          <Col style={{padding:"50px 0px 50px 0px"}}>
            <Container>
              <Row className="ft">
                <Col md="3" style={{padding:"20px"}}>
                  <center>
                  <h4><strong>Company</strong></h4><hr />
                  <p><i className="fa fa-map-marker text-warning" style={{fontSize:"30px"}}></i><br />2nd Floor Parasar Tower, Piska More, Ratu Road, Ranchi - 834005</p>
                  <p><i className="fa fa-phone text-warning" style={{fontSize:"30px"}}></i><br />+91 6207408069</p>
                  <p><i className="fa fa-envelope text-warning" style={{fontSize:"30px"}}></i><br />spwebcare@gmail.com</p>
                  </center>
                </Col>
                <Col md="3" style={{padding:"20px"}}>
                    <h4><strong>Our Services</strong></h4><hr />
                    <p><Link to="/website-designing" className="link"><i className="fa fa-hand-o-right"></i> Website Designing</Link></p>
                    <p><Link to="/website-development" className="link"><i className="fa fa-hand-o-right"></i> Website Development</Link></p>
                    <p><Link to="/graphics-designing" className="link"><i className="fa fa-hand-o-right"></i> Graphics Designing</Link></p>
                    <p><Link to="/digital-marketing" className="link"><i className="fa fa-hand-o-right"></i> Digital Marketing</Link></p>
                    <p><Link to="/cctv-services" className="link"><i className="fa fa-hand-o-right"></i> CCTV Camera Services</Link></p>
                    <p><Link to="/computer-lab" className="link"><i className="fa fa-hand-o-right"></i> Computer Lab Setup</Link></p>
                </Col>
                <Col md="3" style={{padding:"20px"}}>
                    <h4><strong>Useful Links</strong></h4><hr />
                </Col>
                <Col md="3" style={{padding:"20px"}}>
                    <h4><strong>Student Corner</strong></h4><hr />
                    <p><a href="https://verify.spwebcare.com/" className="btn btn-danger">Certificate Varification</a></p>
                    <p><a href="https://spwebcare.com/courses.php" className="btn btn-info">Course & Fees</a></p>
                    <p><a href="https://app.spwebcare.com/"  className="btn btn-success">Student Login</a></p>
                    <p><a href="https://spwebcare.com/online-admission.php" className="btn btn-primary">Online Admission</a></p>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
        <Row style={{backgroundColor:"#000",color:"#fff",padding:"5px"}}>
            <Col md="3">
            <p style={{textAlign:"center",padding:0,margin:0}}>
                &copy; SP WEBCARE 2018 . All Rights Reserved.
            </p>
            </Col>
            <Col md="6"></Col>
            <Col md="3" style={{textAlign:"center"}}>
                <a href="https://www.facebook.com/profile.php?id=61564218457390"><img src={fb} alt="Facebook" style={{height:"40px",padding:"5px"}} /></a>
                <a href="https://www.instagram.com/spwebcare/"><img src={ins} alt="Instagram" style={{height:"40px",padding:"5px"}} /></a>
                <a href="https://www.youtube.com/spwebcare"><img src={yt} alt="Youtube" style={{height:"40px",padding:"5px"}} /></a>
                <a href="https://x.com/spwebcare"><img src={tt} alt="Twitter" style={{height:"40px",padding:"5px"}} /></a>
            </Col>
        </Row>
      </Container>
    );
}

export default Footer;