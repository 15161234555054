import { Col, Container, Row} from "react-bootstrap";
import img1 from "../img/cctv.png";
import Enquiry from "./enquiry";
function Service5()
{
    return (
      <Container>
        <Row className="pd">
          <Col className="heading1">
            <h2 className="h2">CCTV Services</h2>
          </Col>
        </Row>
        <Row style={{ paddingTop: "40px" }} className="align-items-center">
          <Col md="6">
            <h1 style={{ color: "#330066", textAlign: "center" }}>
              CCTV Camera Installation Company in Ranchi<br />
            </h1>
            <h6 style={{ textAlign: "center" }}>
                <span className="text-danger">Free Site Visit </span>
                <span className="text-success">CCTV Installation </span>
                <span className="text-info">CCTV AMC Services</span>
            </h6>
          </Col>
          <Col md="6">
            <img src={img1} alt="About Web Design" className="img-fluid galleryImg" />
          </Col>
        </Row>
        <Row style={{ paddingTop: "30px" }}>
          <Col
            md="7"
            style={{
              boxShadow: "2px 2px 10px #ccc",
              padding: "15px",
              borderRadius: "10px",
              margin:"20px"
            }}
          >
            <p style={{ textAlign: "justify" }}>
            We Provide CCTV Products with 2 Years Warranty, after Sale Services & Customer Support 24/7 according to their Needs & Budget.
            </p>
            
            <p style={{ textAlign: "justify" }}>
              <h5>
                <strong>CCTV Camera Combo Kit Packages with Free Installation In Ranchi</strong>
              </h5> 
              CCTV Installation Cost, Home security systems, contact us for installation & Fitting cost. CCTV Camera Price, cctv camera near me, cctv technician, cctv camera price full set, cctv camera for home, cctv camera shop near me, cctv camera price, cctv, cctv camera, ip cctv camera, network cctv camera, ip cctv camera installation, dvr, nvr, dome camera, bullet camera, WIFI camera, 8 cctv camera, 16 cctv camera, 4 cctv camera, 32 cctv camera, 12 cctv cameras, cctv installer technicians in Ranchi
            </p>
            <p style={{ textAlign: "justify" }}>
              <h5>
                <strong>Quotations</strong>
              </h5>
              Quotations provided by our sales team are made based on current prices available and are valid for 7 days, after which you must obtain a new quote. All offers are subject to availability and while stocks last.
            </p>
            <p style={{ textAlign: "justify" }}>
              <h5>
                <strong>CCTV AMC</strong>
              </h5>
              We undertake CCTV Annual Maintenance Contract for Pre Installed Projects, Apartments, Schools, Hotels, Residential & Commercial Projects in Ranchi, Jamshedpur, Hazaribagh, Lohardaga, Chakradharpur etc.
            </p>
            <p style={{ textAlign: "justify" }}>
              <h5>
                <strong>CCTV Installation & AMC</strong>
              </h5>
              With New Installation avail CCTV Annual Maintenance Contract with Smart features, Comprehensive & Noncomprehensive at affordable prices in Ranchi, Jamshedpur, Hazaribagh, Lohardaga, Chakradharpur etc.
            </p>
          </Col>
          <Col
            md="4"
            style={{
              boxShadow: "2px 2px 10px #ccc",
              padding: "15px",
              borderRadius: "10px",
              margin:"20px"
            }}
          >
            <Enquiry />
          </Col>
        </Row>
      </Container>
    );
}

export default Service5;