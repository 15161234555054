import { Col, Container, Row} from "react-bootstrap";
import img1 from "../img/web_design.png";
import Enquiry from "./enquiry";
function Service1()
{
    return (
      <Container>
        <Row className="pd">
          <Col className="heading1">
            <h2 className="h2">Website Designing</h2>
          </Col>
        </Row>
        <Row style={{ paddingTop: "40px" }} className="align-items-center">
          <Col md="6">
            <h1 style={{ color: "#330066", textAlign: "center" }}>
              Website Design Company in Ranchi
            </h1>
          </Col>
          <Col md="6">
            <img src={img1} alt="About Web Design" className="img-fluid galleryImg" />
          </Col>
        </Row>
        <Row style={{ paddingTop: "30px" }}>
          <Col
            md="7"
            style={{
              boxShadow: "2px 2px 10px #ccc",
              padding: "15px",
              borderRadius: "10px",
              margin:"20px"
            }}
          >
            <p style={{ textAlign: "justify" }}>
              Your business face in the virtual medium is the website that will
              represent your values and company quality on the internet, we at{" "}
              <strong>SP WEBCARE</strong> provide professional web design
              services which includes eCommerce web design, social network
              design, logo design and much more. We are the number one web
              design company in <strong>Ranchi</strong> offering expert design
              services to several types of companies ranging from small to large
              companies.
            </p>
            <p style={{ textAlign: "justify" }}>
              The famous proverb says that the first impression is a good
              impression, so you should have a perfect and attractive website to
              represent your business in the digital medium because your site is
              the showcase to display all your products and services.
            </p>
            <p style={{ textAlign: "justify" }}>
              <h5>
                <strong>We Give You The Best Design</strong>
              </h5>
              We are one of the leading{" "}
              <strong>web design companies in Ranchi</strong> who maintain
              professionalism in their services because we provide services at
              top-notch quality. We are the answer to your web design near me
              search engine query.
            </p>
            <p style={{ textAlign: "justify" }}>
              <h5>
                <strong>One-Stop For All Your Needs</strong>
              </h5>
              We design a website that can deliver 100 percent work effortlessly
              and if you have responsive Web Design created by us, then you have
              a bright future in visual marketing. We provide you with a design,
              you can control your website easily, you can improve your brand
              reputation among people, you can earn customer satisfaction easily
              and also you can get a raise in income.
            </p>
          </Col>
          <Col
            md="4"
            style={{
              boxShadow: "2px 2px 10px #ccc",
              padding: "15px",
              borderRadius: "10px",
              margin:"20px"
            }}
          >
            <Enquiry />
          </Col>
        </Row>
      </Container>
    );
}

export default Service1;