import { Link } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';
import sl1 from '../img/4.jpg';
import sl2 from '../img/1.jpg';
import sl3 from '../img/2.jpg';
import pro1 from '../img/pro1.gif';
import ab from '../img/about.gif';
import ser1 from '../img/service (6).png';import ser2 from '../img/service (4).png';
import ser3 from '../img/service (3).png';import ser4 from '../img/service (5).png';
import ser5 from '../img/service (2).png';import ser6 from '../img/service (1).png';
import {Col, Row, Container, Card} from 'react-bootstrap';
import AOS from "aos";
import "aos/dist/aos.css";

function Home() {
  AOS.init()
  return (
    <>
    <Carousel fade>
      <Carousel.Item>
        <img src={sl1} alt='hello' className='sl11' />
        <Carousel.Caption>
          <Row>
            <Col className='ct'>
                <h3>We Are Creative Agency</h3>
                <p>We are a passionate digital design agency that specializes in beautiful and easy-to-use digital design & web development services.We are a passionate digital design agency that specializes in beautiful and easy-to-use digital design & web development services.</p>
            </Col>
          </Row>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img src={sl2} alt='hello' className='sl11' />
        <Carousel.Caption>
        <Row>
            <Col className='ct'>
                <h3>About Our Creative Agency</h3>
                <p>We believe in power of User Experience that Strategic Insight, Responsive Layouts, Unique Design, Clean Coding and Deliver.</p>
            </Col>
          </Row>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img src={sl3} alt='hello' className='sl11' />
        <Carousel.Caption>
        <Row>
            <Col className='ct'>
                <h3>Our Process</h3>
                <p>We are a passionate digital design agency that specializes in beautiful and easy-to-use digital design & web development services.</p>
            </Col>
          </Row>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
    <Container>
        <Row style={{paddingTop:"30px"}}  data-aos="flip-up" data-aos-duration="3000">
          <Col>
          <h2 className='h21'>About Us</h2>
          <h6 style={{textAlign:"center",color:"#330066"}}>We believe in power of User Experience that Strategic Insight, Responsive Layouts, Unique Design, Clean Coding and Deliver.</h6>
          </Col>
        </Row>
        <Row style={{paddingTop:"15px"}}>
          <Col style={{textAlign:"justify"}} md="6" data-aos="flip-up">
            <p>SP WEBCARE was established in 2017 Promoting Director Shyam Sundar Pradhan with a vision of delivering innovative and cost effective solutions leveraging Open Source and hardware. The company has its headquarters in Ranchi, India and has two development centers in India located in Ranchi and Seraikella-Kharsawan, Jharkhand. Our offring include smart and optimised design for web, mobile and tablet, hosting, maintenance and support services.</p>
            <p>At SP WEBCARE we are a team of young, passionate and creative people who aim to offer world-class solutions for all your web design and development needs. We create meaningful brand digital experiences, from domain registration to website design and finally website hosting for all small, medium and large entrepreneurs around the world.</p>
          </Col>
          <Col style={{textAlign:"center"}} md="6" data-aos="flip-up">
            <br /><img src={ab} className='img-fluid' alt="about" />
            <br /><br /><Link to="/about" className='btn btn-danger'>read more</Link>
          </Col>
        </Row>
    </Container>
    <Container fluid>
      <Row style={{paddingTop:20}}>
        <Col className='px-0'>
          <img src={pro1} className='img-fluid' alt="" />
        </Col>
      </Row>
    </Container>  
    <Container>
      <Row style={{paddingTop:"40px"}}  data-aos="flip-up" data-aos-duration="3000">
        <Col>
          <h2 className='h21'>Our Services</h2>
          <h6 style={{textAlign:"center",color:"#330066"}}>We Offer Professional Solutions For Your Business</h6>
        </Col>
      </Row>
      <Row style={{paddingTop:"15px"}}>
          <Col style={{textAlign:"justify",padding:"10px"}} md="4" data-aos="flip-up"data-aos-duration="1000">
            <Card style={{boxShadow:'2px 2px 10px #000' }}>
              <Card.Header className='bg-warning' />
              <Card.Body style={{textAlign:'center'}}>
                <img src={ser1} style={{height:80,width:80}} alt="" />
                <Card.Title style={{color:"#330066"}}>Website Designing</Card.Title>
                <Card.Text>
                Stand out from the crowd with a stunning website by SP WEBCARE Web Design Services.
                </Card.Text>
                <Link to="/website-designing" className='btn btn-primary btn-sm'>Read More</Link>
              </Card.Body>
            </Card>
          </Col>
          <Col style={{textAlign:"justify",padding:"10px"}} md="4" data-aos="flip-up"data-aos-duration="1000">
            <Card style={{ boxShadow:'2px 2px 10px #000' }}>
              <Card.Header className='bg-warning' />
              <Card.Body style={{textAlign:'center'}}>
                <img src={ser2} style={{height:80,width:80}} alt="" />
                <Card.Title style={{color:"#330066"}}>Website Development</Card.Title>
                <Card.Text>
                Secure, Intuitive, Scalable web apps to help you exponentially grow your business.
                </Card.Text>
                <Link to="/website-development" className='btn btn-primary btn-sm'>Read More</Link>
              </Card.Body>
            </Card>
          </Col>
          <Col style={{textAlign:"justify",padding:"10px"}} md="4" data-aos="flip-up"data-aos-duration="1000">
            <Card style={{ boxShadow:'2px 2px 10px #000' }}>
              <Card.Header className='bg-warning' />
              <Card.Body style={{textAlign:'center'}}>
                <img src={ser3} style={{height:80,width:80}} alt="" />
                <Card.Title style={{color:"#330066"}}>Digital Marketing</Card.Title>
                <Card.Text>
                It is a strategy that uses multiple channels to attract engage and convert customers online.
                </Card.Text>
                <Link to="/digital-marketing" className='btn btn-primary btn-sm'>Read More</Link>
              </Card.Body>
            </Card>
          </Col>
          <Col style={{textAlign:"justify",padding:"10px"}} md="4" data-aos="flip-up"data-aos-duration="1000">
            <Card style={{ boxShadow:'2px 2px 10px #000' }}>
              <Card.Header className='bg-warning' />
              <Card.Body style={{textAlign:'center'}}>
                <img src={ser4} style={{height:80,width:80}} alt="" />
                <Card.Title style={{color:"#330066"}}>Graphics Designing</Card.Title>
                <Card.Text>
                Stand out from the crowd with a stunning website by SP WEBCARE Web Design Services.
                </Card.Text>
                <Link to="/graphics-designing" className='btn btn-primary btn-sm'>Read More</Link>
              </Card.Body>
            </Card>
          </Col>
          <Col style={{textAlign:"justify",padding:"10px"}} md="4" data-aos="flip-down"data-aos-duration="1000">
            <Card style={{ boxShadow:'2px 2px 10px #000' }}>
              <Card.Header className='bg-warning' />
              <Card.Body style={{textAlign:'center'}}>
                <img src={ser5} style={{height:80,width:80}} alt="" />
                <Card.Title style={{color:"#330066"}}>CCTV Services</Card.Title>
                <Card.Text>
                Stand out from the crowd with a stunning website by SP WEBCARE Web Design Services.
                </Card.Text>
                <Link to="/cctv-services" className='btn btn-primary btn-sm'>Read More</Link>
              </Card.Body>
            </Card>
          </Col>
          <Col style={{textAlign:"justify",padding:"10px"}} md="4" data-aos="flip-up"data-aos-duration="1000">
            <Card style={{boxShadow:'2px 2px 10px #000' }}>
              <Card.Header className='bg-warning' />
              <Card.Body style={{textAlign:'center'}}>
                <img src={ser6} style={{height:80,width:80}} alt="kkkl" />
                <Card.Title style={{color:"#330066"}}>Computer Lab</Card.Title>
                <Card.Text>
                Stand out from the crowd with a stunning website by SP WEBCARE Web Design Services.
                </Card.Text>
                <Link to="/computer-lab" className='btn btn-primary btn-sm'>Read More</Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row style={{paddingTop:"40px"}}  data-aos="flip-up" data-aos-duration="3000">
          <Col>
            <h2 className='h21'>Satisfied Clients</h2>
            <h6 style={{textAlign:"center",color:"#330066",display:"none"}}>We Offer Professional Solutions For Your Business</h6>
          </Col>
        </Row>
        <Row style={{backgroundColor:"#ccc"}}  data-aos="flip-up">
          <Col md="3" style={{border:"1px solid #000",padding:"10px",textAlign:"center"}}><img src='https://sangamps.com/uploads/school_content/admin_logo/1.png' alt='llll' style={{padding:"20px"}} className='img-fluid client'  /></Col>
          <Col md="3" style={{border:"1px solid #000",padding:"10px",textAlign:"center"}}><img src='https://gurukulshiksha.com/logo.png' alt='llll' style={{padding:"20px"}} className='img-fluid client' /></Col>
          <Col md="3" style={{border:"1px solid #000",padding:"10px",textAlign:"center"}}><img src='http://shineedupoint.com/uploads/school_content/logo/front_logo-63aeba926e2464.92006223.png' alt='llll'  style={{padding:"20px"}} className='img-fluid client' /></Col>
          <Col md="3" style={{border:"1px solid #000",padding:"10px",textAlign:"center"}}><img src='https://spposcare.com/uploads/logo.png' alt='llll' style={{padding:"20px"}} className='img-fluid client' /></Col>
          <Col md="3" style={{border:"1px solid #000",padding:"10px",textAlign:"center"}}><img src='https://kanishq.org.in/img/logo.png' alt='llll' style={{padding:"20px"}} className='img-fluid client'  /></Col>
          <Col md="3" style={{border:"1px solid #000",padding:"10px",textAlign:"center"}}><img src='https://delightagritech.com/img/logo1.png' alt='llll'  style={{padding:"20px"}} className='img-fluid client'  /></Col>
          <Col md="3" style={{border:"1px solid #000",padding:"10px",textAlign:"center"}}><img src='https://mahalaxmiplantation.com/img/logo.png' alt='llll' style={{padding:"20px"}} className='img-fluid client'  /></Col>
          <Col md="3" style={{border:"1px solid #000",padding:"10px",textAlign:"center"}}><img src='https://collegejunctions.com/img/logo.png' alt='llll' style={{padding:"20px"}} className='img-fluid client'  /></Col>
        </Row>

    </Container>
    
    </>
  )
}

export default Home;