import { Col, Container, Row} from "react-bootstrap";
import img1 from "../img/website_development.png";
import Enquiry from "./enquiry";
function Service2()
{
    return (
      <Container>
        <Row className="pd">
          <Col className="heading1">
            <h2 className="h2">Website Development</h2>
          </Col>
        </Row>
        <Row style={{ paddingTop: "40px" }} className="align-items-center">
          <Col md="6">
            <h1 style={{ color: "#330066", textAlign: "center" }}>
              Website Development Company in Ranchi
            </h1>
          </Col>
          <Col md="6">
            <img src={img1} alt="About Web Design" className="img-fluid galleryImg" />
          </Col>
        </Row>
        <Row style={{ paddingTop: "30px" }}>
          <Col
            md="7"
            style={{
              boxShadow: "2px 2px 10px #ccc",
              padding: "15px",
              borderRadius: "10px",
              margin:"20px"
            }}
          >
            <p style={{ textAlign: "justify" }}>
            As we are living in the era of the digital world, the website is the main core of any business. A website is one of the prominent features of digital marketing, not only a website but a beautiful and well-designed website that enhance features of any business. To increase the reach of business and profit ration it is must digitalize the business to gain maximum reach to customers and acquire your space in the cut-throat competitive market. The Internet has unlimited power and reaches to the target customers for business and services provided by any website development company. The best way to utilize the power of the internet is to go digital and speared the business like butter on a slice.
            </p>
            <p style={{ textAlign: "justify" }}>
              <strong>SP WEBCARE</strong> being a top-notch <strong>website developing company in Ranchi</strong> is providing all kind of website development services that too at an effective cost. We are having a creative, dedicated and experienced team with us to complete all operations regarding websites. SP WEBCARE is a well progressive <strong>Web Development Company in Ranchi</strong>, which is working across the globe.
            </p>
            <p style={{ textAlign: "justify" }}>
              <h5>
                <strong>We Give You The Best Design</strong>
              </h5>
              We are one of the leading{" "}
              <strong>web development companies in Ranchi</strong> who maintain
              professionalism in their services because we provide services at
              top-notch quality. We are the answer to your web design near me
              search engine query.
            </p>
            <p style={{ textAlign: "justify" }}>
              <h5>
                <strong>One-Stop For All Your Needs</strong>
              </h5>
              We design a website that can deliver 100 percent work effortlessly
              and if you have responsive Web Design created by us, then you have
              a bright future in visual marketing. We provide you with a design,
              you can control your website easily, you can improve your brand
              reputation among people, you can earn customer satisfaction easily
              and also you can get a raise in income.
            </p>
          </Col>
          <Col
            md="4"
            style={{
              boxShadow: "2px 2px 10px #ccc",
              padding: "15px",
              borderRadius: "10px",
              margin:"20px"
            }}
          >
            <Enquiry />
          </Col>
        </Row>
      </Container>
    );
}

export default Service2;